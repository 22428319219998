export const INVENTORY_STATE_CARDBOARD_STANDBY = "Cartons STANDBY";
export const INVENTORY_STATE_CARDBOARD_DON = "Cartons PAC/DON";
export const INVENTORY_STATE_TO_STORE_RETRIEVAL = "À RANGER - REPRISE";
export const INVENTORY_STATE_CARDBOARD_BERRY = "Cartons BERRY";

const stateColorMapping: Record<string, string> = {
    [INVENTORY_STATE_TO_STORE_RETRIEVAL]: "#ADD8E6",
    [INVENTORY_STATE_CARDBOARD_BERRY]: "#FF6666",
    [INVENTORY_STATE_CARDBOARD_DON]: "yellow",
    [INVENTORY_STATE_CARDBOARD_STANDBY]: "violet"
};

const getInventoryStateColor = (state: string): string => {
    return stateColorMapping[state] || "black";
};

export default getInventoryStateColor;
