import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { handleCallErrorWithLoader } from "services/exceptions";
import { patchProductInfos, postProductInfos } from "services/products";
import { fetchStockFromBarcode } from "services/stocks";
import { handleStockFormatting } from "helpers/stocks/handleStockFormatting";

import { Container } from "@mui/material";
import ProductConfirm from "components/productConfirm/ProductConfirm";
import ScreenTitle from "components/screenTitle";
import StandbyConfirm from "components/productConfirm/StandbyConfirm";

import AttractivityTagRatingScale from "./sections/ratingScales/AttractivityTag";
import BrandAutocompleteField from "./sections/autocompleteFields/Brand";
import BrandNewPriceTextField from "./sections/textfields/BrandNewPrice";
import MeasureTextField from "./sections/textfields/MeasureTextField";
import CancelButton from "./sections/buttons/Cancel";
import CategoryAutocompleteField from "./sections/autocompleteFields/Category";
import ColorsToggle from "./sections/toggleButtonGroups/Colors";
import CommentsToggle from "./sections/toggleButtonGroups/Comments";
import ConditionRatingScale from "./sections/ratingScales/Condition";
import FlawsToggle from "./sections/toggleButtonGroups/Flaws";
import InternalNotesNoteField from "./sections/noteFields/InternalNotes";
import MaterialAutocompleteField from "./sections/autocompleteFields/Material";
import NotesInputField from "./sections/noteFields/NotesInputField";
import PhotoBrandButton from "./sections/uploadButtons/PhotoBrand";
import PhotoFlawButton from "./sections/uploadButtons/PhotoFlaw";
import PhotoCoverButton from "./sections/uploadButtons/PhotoCover";
import PhotoLabelButton from "./sections/uploadButtons/PhotoLabel";
import PhotoOtherButton from "./sections/uploadButtons/PhotoOther";
import PhotoOther2Button from "./sections/uploadButtons/PhotoOther2";
import PhotoOther3Button from "./sections/uploadButtons/PhotoOther3";
import PhotoPriceButton from "./sections/uploadButtons/PhotoPrice";
import PhotoMaterialButton from "./sections/uploadButtons/PhotoMaterial";
import PhotoReverseButton from "./sections/uploadButtons/PhotoReverse";
import RefusalReasonsToggle from "./sections/toggleButtonGroups/RefusalReasons";
import RefuseButton from "./sections/buttons/Refuse";
import SizeAutocompleteField from "./sections/autocompleteFields/Size";
import StandbyButton from "./sections/buttons/Standby";
import SubmitButton from "./sections/buttons/Submit";

import { clearErrorMessage, setErrorMessage } from "store/errorSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectPrice } from "store/priceSlice";
import { updateProduct, selectProduct } from "store/productSlice";
import { selectSettings } from "store/settingsSlice";
import { enableCloseStock, selectStock, setNumberOfProcessedProducts } from "store/stockSlice";

import { useStudioAccessRights } from "hooks/rolesRights";

import { ROLE_1, ROLE_2 } from "constants/usersRules";
import type { RequiredProductFormFields, UsualValue } from "types/main";
import type {
    ProductPhoto,
    ProductPhotoToPost,
    NestedCriteria,
    ProductMeasureToPost,
    ProductMeasure
} from "types/product";

import formStyles from "./ProductForm.module.css";

/**
 * Component props
 */
interface Props {
    productStatus: string;
}

const ProductForm = ({ productStatus }: Props): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { register, getValues, handleSubmit, formState, control, setValue, unregister } = useForm(
        {
            mode: "onSubmit"
        }
    );

    const product = useAppSelector(selectProduct);
    const price = useAppSelector(selectPrice);
    const settings = useAppSelector(selectSettings);
    const stock = useAppSelector(selectStock);

    const [categoryToPost, setCategoryToPost] = useState<NestedCriteria>();
    const [currentProductStatus, setCurrentProductStatus] = useState<string>(productStatus);
    const [hasErrorDialogBeenDisplayed, setHasErrorDialogBeenDisplayed] = useState<boolean>(false);
    const [isProductConfirmOpen, setIsProductConfirmOpen] = useState<boolean>(false);
    const [requiredFormFields, setRequiredFormFields] = useState<RequiredProductFormFields[]>([]);
    const [selectedFlaws, setSelectedFlaws] = useState<UsualValue[]>([]);
    const [selectedComments, setSelectedComments] = useState<UsualValue[]>([]);

    useEffect(() => {
        dispatch(enableCloseStock(false));
        dispatch(clearErrorMessage());

        // Redirects if no product found
        // @todo: Check if product.sku is empty and no stock opened
        if (!product.sku && !product.item_qr && !isProductRefused()) {
            navigate("/studio/product/open");
        }
    }, []);

    /**
     * Manage conditional required fields
     */
    useEffect(() => {
        const requiredFields: RequiredProductFormFields[] = [];
        const optionalFields: string[] = ["flaws_notes", "photo_FLAW"];

        if (currentProductStatus !== "STANDBY") {
            if (settings.role === ROLE_1 && !isProductRefused()) {
                requiredFields.push({ name: "condition", options: { required: "Etat" } });
            }
            if (isProductRefused()) {
                requiredFields.push(
                    {
                        name: "public_notes",
                        options: { required: "Raison du refus" }
                    },
                    {
                        name: "categories2",
                        options: { required: false }
                    }
                );
                !handleExistantPhotos("OTHER") &&
                    requiredFields.push({
                        name: "photo_OTHER",
                        options: { required: "Photo autre" }
                    });
            } else {
                if (settings.role && [ROLE_2].includes(settings.role)) {
                    !handleExistantPhotos("COVER") &&
                        requiredFields.push({
                            name: "photo_COVER",
                            options: { required: "Photo face" }
                        });
                    !handleExistantPhotos("REVERSE") &&
                        requiredFields.push({
                            name: "photo_REVERSE",
                            options: { required: "Photo dos" }
                        });
                }

                if (settings.role && [ROLE_1].includes(settings.role)) {
                    requiredFields.push({
                        name: "attractivity_tag",
                        options: { required: "Attractivité" }
                    });
                }

                if (settings.role && [ROLE_1].includes(settings.role)) {
                    requiredFields.push({
                        name: "size",
                        options: { required: "Taille" }
                    });
                    requiredFields.push({
                        name: "photo_BRAND",
                        options: { required: "Photo marque" }
                    });
                    requiredFields.push({
                        name: "photo_LABEL",
                        options: { required: false }
                    });

                    requiredFields.push({
                        name: "measure_width",
                        options: { required: false }
                    });
                }

                if (
                    product.condition?.label === "Petits défauts" ||
                    product.condition?.label === "Bon état"
                ) {
                    requiredFields.push({
                        name: "flaws_notes",
                        options: {
                            required:
                                (product.condition.label === "Petits défauts" && "Défauts") ?? false
                        }
                    });

                    if (
                        settings.role &&
                        [ROLE_1, ROLE_2].includes(settings.role) &&
                        !handleExistantPhotos("FLAW")
                    ) {
                        requiredFields.push({
                            name: "photo_FLAW",
                            options: { required: "Photo défaut" }
                        });
                    }
                }
            }
        } else {
            requiredFields.push(
                { name: "attractivity_tag", options: { required: false } },
                { name: "categories2", options: { required: false } },
                { name: "color", options: { required: false } },
                { name: "condition", options: { required: false } },
                { name: "photo_COVER", options: { required: false } },
                { name: "photo_OTHER", options: { required: false } },
                { name: "photo_REVERSE", options: { required: false } },
                { name: "public_notes", options: { required: false } },
                { name: "size", options: { required: false } }
            );
        }

        optionalFields.forEach((field) => {
            if (!requiredFields.some((requiredField) => requiredField.name === field)) {
                unregister(field);
            }
        });
        requiredFields.forEach((field) => field && register(field.name, field.options));
        setRequiredFormFields(requiredFields);
    }, [product, currentProductStatus, selectedFlaws, selectedComments]);

    /**
     * Dispatch error message for required fields
     */
    useEffect(() => {
        if (
            !hasErrorDialogBeenDisplayed &&
            formState.errors &&
            Object.keys(formState.errors).length
        ) {
            let errorFields = "";
            for (const key in formState.errors) {
                errorFields += ` ${String(formState.errors[key]?.message)},`;
            }
            dispatch(setErrorMessage(`Champs requis :${errorFields.slice(0, -1)}`));
            setHasErrorDialogBeenDisplayed(true);
        }
    }, [formState]);

    /**
     * Form submit
     * - Post product
     * - Upload price if new
     * - Display confirm modal
     */
    const onSubmit = async (data: any): Promise<void> => {
        dispatch(clearErrorMessage());

        const photos: ProductPhotoToPost[] = [];
        const measures: ProductMeasureToPost[] = [];

        for (const key in data) {
            // Delete category (categoryToPost is used instead)
            if (key.includes("categories")) {
                delete data[key];
            }

            // If brand is deleted, insert an empty object
            if (data.brand === null) {
                data.brand = {};
            }
            // If key is empty, do not send value
            if (data[key] === null || data[key] === undefined || data[key].length === 0) {
                delete data[key];
            }
            // Store all keys begining with "photo_" to new array
            if (key.includes("photo_") && data[key] !== undefined) {
                photos.push({
                    format: key.slice(6),
                    photo_url: data[key]
                });
                delete data[key];
            }

            // Store all keys begining with "measure_" to new array
            if (key.includes("measure_") && data[key] !== undefined) {
                measures.push({
                    type: key.slice(8).toUpperCase(),
                    value: data[key]
                });
                delete data[key];
            }
        }

        // Add null inventory if product is refused
        if (isProductRefused()) {
            data = { inventory: 0, ...data };
        }

        // Add category
        if (categoryToPost) {
            data = { category: categoryToPost, ...data };
        }

        // Add photos to payload
        if (photos.length) {
            data = { photos, ...data };
        }

        if (measures.length) {
            data = { measures, ...data };
        }

        // Add price to payload for new product (if price is set)
        if (product.condition?.label === "Neuf avec etiquette" && price.value) {
            data = { prices: [price], ...data };
        }

        if (settings.role && [ROLE_2].includes(settings.role)) {
            data = { is_ready_for_attributes_detection: true, ...data };
        }

        const result = await postProduct(data);
        if (result?.sku) {
            setCurrentProductStatus("ACCEPTED");

            // Update stock number of processed products
            if (stock.barcode) {
                const currentStock = await handleCallErrorWithLoader(
                    dispatch,
                    fetchStockFromBarcode,
                    stock.barcode
                );
                if (currentStock) {
                    dispatch(
                        setNumberOfProcessedProducts(currentStock.number_of_processed_products)
                    );

                    localStorage.setItem(
                        "stock",
                        JSON.stringify(handleStockFormatting(currentStock))
                    );
                }
            }
        }
        setIsProductConfirmOpen(true);
    };

    async function postProduct(data: any): Promise<any> {
        data = { stock_status: currentProductStatus, ...data };
        let result = null;
        if (product.sku) {
            result = await handleCallErrorWithLoader(
                dispatch,
                patchProductInfos,
                data,
                product.sku
            );
        } else {
            data = { stock: stock.barcode, item_qr: product.item_qr, ...data };
            result = await handleCallErrorWithLoader(dispatch, postProductInfos, data);
        }
        if (result) {
            resetNotesLists();
            dispatch(updateProduct(result));
            return result;
        } else {
            dispatch(setErrorMessage(`Erreur : le produit n'a pas été enregistré`));
        }
    }

    const resetNotesLists = (): void => {
        setSelectedComments([]);
        setSelectedFlaws([]);
    };

    /**
     * Product status management
     */
    const isProductRefused = (): boolean => {
        return currentProductStatus === "REFUSED";
    };

    /**
     * Required fields management
     */
    const areRequiredFieldsFilledIn = (): boolean => {
        return Object.keys(formState.errors).length === 0;
    };

    const isFieldRequired = (fieldName: string): boolean => {
        return requiredFormFields.some((field) => field.name === fieldName);
    };

    /**
     * Search existant photos
     */
    const handleExistantPhotos = (photoName: string): ProductPhoto | undefined => {
        if (product?.photos?.length) {
            const matchingPhotos = product.photos.filter((photo) => photo.format === photoName);
            return matchingPhotos[0];
        }
    };

    /**
     * Search existant measures
     */
    const handleExistantMeasure = (measureName: string): ProductMeasure | undefined => {
        if (product?.measures?.length) {
            const matchingMeasures = product.measures.filter(
                (measure) => measure.type === measureName
            );

            return matchingMeasures[0];
        }
    };

    /**
     * Format comments & flaws notes before filling each fields
     */
    const formatNotes = (notesList: UsualValue[]): string => {
        return notesList
            .reduce((accumulator: string, item) => (accumulator += `${String(item.value)}\r\n`), "")
            .slice(0, -2);
    };

    /**
     * Render component
     */
    return (
        <Container maxWidth="sm">
            <React.Fragment>
                {!isProductRefused() && (
                    <>
                        {product.sku && product.item_qr ? (
                            <ScreenTitle text={`Edition du produit ${String(product.item_qr)}`} />
                        ) : (
                            <ScreenTitle text={"Nouveau produit"} />
                        )}
                    </>
                )}
                {isProductRefused() && <ScreenTitle text={"Produit à refuser"} />}

                <form onSubmit={handleSubmit(onSubmit)}>
                    {useStudioAccessRights("PRODUCT_FORM_FIELD_REFUSED_FLAWS") &&
                        isProductRefused() && (
                            <RefusalReasonsToggle
                                control={control}
                                selectedFlaws={selectedFlaws}
                                setSelectedFlaws={setSelectedFlaws}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_COVER") &&
                        !isProductRefused() && (
                            <PhotoCoverButton
                                control={control}
                                existantPhoto={handleExistantPhotos("COVER")}
                                isRequired={isFieldRequired("photo_COVER")}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_REVERSE") &&
                        !isProductRefused() && (
                            <PhotoReverseButton
                                control={control}
                                existantPhoto={handleExistantPhotos("REVERSE")}
                                isRequired={isFieldRequired("photo_REVERSE")}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_BRAND") &&
                        !isProductRefused() && (
                            <PhotoBrandButton
                                control={control}
                                existantPhoto={handleExistantPhotos("BRAND")}
                            />
                        )}

                    {(useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_OTHER") ||
                        isProductRefused()) && (
                        <PhotoOtherButton
                            control={control}
                            existantPhoto={handleExistantPhotos("OTHER")}
                            isRequired={isFieldRequired("photo_OTHER")}
                        />
                    )}

                    {(useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_OTHER2") ||
                        isProductRefused()) && (
                        <PhotoOther2Button
                            control={control}
                            existantPhoto={handleExistantPhotos("OTHER2")}
                        />
                    )}

                    {(useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_OTHER3") ||
                        isProductRefused()) && (
                        <PhotoOther3Button
                            control={control}
                            existantPhoto={handleExistantPhotos("OTHER3")}
                        />
                    )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_LABEL") &&
                        !isProductRefused() && (
                            <PhotoLabelButton
                                control={control}
                                existantPhoto={handleExistantPhotos("LABEL")}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_MATERIAL") &&
                        !isProductRefused() && (
                            <PhotoMaterialButton
                                control={control}
                                existantPhoto={handleExistantPhotos("MATERIAL")}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_SIZE") && !isProductRefused() && (
                        <SizeAutocompleteField
                            categoryToPost={categoryToPost}
                            control={control}
                            isRequired={isFieldRequired("size")}
                            setValue={setValue}
                        />
                    )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_MEASUREMENT") &&
                        !isProductRefused() && (
                            <>
                                <MeasureTextField
                                    control={control}
                                    existingMeasure={handleExistantMeasure("WIDTH")}
                                    label="Mesure horizontale (en cm) 🎤: horizontal"
                                    name="measure_width"
                                    recognition_label="horizontal"
                                />

                                <MeasureTextField
                                    control={control}
                                    existingMeasure={handleExistantMeasure("LENGTH")}
                                    label="Mesure verticale (en cm) 🎤: vertical"
                                    name="measure_length"
                                    recognition_label="vertical"
                                />

                                <MeasureTextField
                                    control={control}
                                    existingMeasure={handleExistantMeasure("SLEEVE_LENGTH")}
                                    label="Manche (en cm), 0 si pas de manche 🎤: manche"
                                    name="measure_sleeve_length"
                                    recognition_label="manche"
                                />

                                <MeasureTextField
                                    control={control}
                                    existingMeasure={handleExistantMeasure("INSEAM_LENGTH")}
                                    label="Entrejambe (en cm) 🎤: hauteur"
                                    name="measure_inseam_length"
                                    recognition_label="hauteur"
                                />
                            </>
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_CONDITION") &&
                        !isProductRefused() && (
                            <ConditionRatingScale
                                control={control}
                                isRequired={isFieldRequired("condition")}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_USUAL_FLAWS") &&
                        (product.condition?.label === "Petits défauts" ||
                            product.condition?.label === "Bon état") &&
                        !isProductRefused() && (
                            <FlawsToggle
                                control={control}
                                productCondition={product.condition?.label}
                                selectedFlaws={selectedFlaws}
                                setSelectedFlaws={setSelectedFlaws}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_FLAW") &&
                        !isProductRefused() &&
                        (product.condition?.label === "Petits défauts" ||
                            product.condition?.label === "Bon état") && (
                            <PhotoFlawButton
                                control={control}
                                existantPhoto={handleExistantPhotos("FLAW")}
                                isRequired={isFieldRequired("photo_FLAW")}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PRICE") &&
                        !isProductRefused() &&
                        product.condition?.label === "Neuf avec etiquette" && (
                            <BrandNewPriceTextField />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PHOTO_PRICE_LABEL") &&
                        !isProductRefused() &&
                        product.condition?.label === "Neuf avec etiquette" && (
                            <PhotoPriceButton
                                control={control}
                                existantPhoto={handleExistantPhotos("PRICE")}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_CATEGORY") &&
                        !isProductRefused() && (
                            <CategoryAutocompleteField
                                categoryToPost={categoryToPost}
                                control={control}
                                setCategoryToPost={setCategoryToPost}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_COLOR") && !isProductRefused() && (
                        <ColorsToggle control={control} />
                    )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_ATTRACTIVITY") &&
                        !isProductRefused() && (
                            <AttractivityTagRatingScale
                                control={control}
                                isRequired={isFieldRequired("attractivity_tag")}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_BRAND") && !isProductRefused() && (
                        <BrandAutocompleteField control={control} />
                    )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_MATERIAL") &&
                        !isProductRefused() && <MaterialAutocompleteField control={control} />}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_USUAL_COMMENTS") &&
                        !isProductRefused() && (
                            <CommentsToggle
                                selectedComments={selectedComments}
                                setSelectedComments={setSelectedComments}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_USUAL_FLAWS") &&
                        isProductRefused() && (
                            <FlawsToggle
                                control={control}
                                productCondition={"Refusé"}
                                selectedFlaws={selectedFlaws}
                                setSelectedFlaws={setSelectedFlaws}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_FLAWS") &&
                        !isProductRefused() &&
                        (product.condition?.label === "Petits défauts" ||
                            product.condition?.label === "Bon état") && (
                            <NotesInputField
                                control={control}
                                existantNote={product?.flaws_notes}
                                formatNotes={formatNotes}
                                isRequired={isFieldRequired("flaws_notes")}
                                noteType="flaws_notes"
                                selectedUsualValues={selectedFlaws}
                                setValue={setValue}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PUBLIC_NOTES") &&
                        !isProductRefused() && (
                            <NotesInputField
                                control={control}
                                existantNote={product?.public_notes}
                                formatNotes={formatNotes}
                                noteType="public_notes"
                                selectedUsualValues={selectedComments}
                                setValue={setValue}
                            />
                        )}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PRIVATE_NOTES") &&
                        !isProductRefused() && <InternalNotesNoteField control={control} />}

                    {useStudioAccessRights("PRODUCT_FORM_FIELD_PUBLIC_NOTES") &&
                        isProductRefused() && (
                            <NotesInputField
                                control={control}
                                existantNote={product?.public_notes}
                                formatNotes={formatNotes}
                                isRequired={isFieldRequired("public_notes")}
                                noteType="public_notes"
                                selectedUsualValues={selectedFlaws}
                                setValue={setValue}
                            />
                        )}

                    <div className={formStyles.productForm__buttons}>
                        <div className={formStyles.productForm__firstButtons}>
                            {!isProductRefused() && (
                                <>
                                    <RefuseButton
                                        areRequiredFieldsFilledIn={areRequiredFieldsFilledIn()}
                                        onClick={() => {
                                            setCurrentProductStatus("REFUSED");
                                        }}
                                    />
                                    <StandbyButton
                                        areRequiredFieldsFilledIn={areRequiredFieldsFilledIn()}
                                        onClick={() => {
                                            setCurrentProductStatus("STANDBY");
                                        }}
                                    />
                                </>
                            )}
                            <SubmitButton
                                areRequiredFieldsFilledIn={areRequiredFieldsFilledIn()}
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                        <CancelButton />
                    </div>
                </form>

                <ProductConfirm
                    isOpen={isProductConfirmOpen}
                    product={product}
                    setIsOpen={setIsProductConfirmOpen}
                />

                <StandbyConfirm
                    currentProductStatus={currentProductStatus}
                    message={getValues("internal_notes")}
                    onClick={handleSubmit(onSubmit)}
                    setCurrentProductStatus={setCurrentProductStatus}
                />
            </React.Fragment>
        </Container>
    );
};

export default ProductForm;
