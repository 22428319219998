import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { fetchLocationFromBarcode, patchProductsLocation } from "services/locations";
import { handleCallErrorWithLoader } from "services/exceptions";
import getInventoryStateColor, {
    INVENTORY_STATE_CARDBOARD_STANDBY,
    INVENTORY_STATE_CARDBOARD_DON,
    INVENTORY_STATE_TO_STORE_RETRIEVAL,
    INVENTORY_STATE_CARDBOARD_BERRY
} from "helpers/studio/getInventoryStateColor";
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import ChoiceButton from "components/buttons/ChoiceButton";
import ScanInputField from "components/formFields/ScanInputField";
import UndoButton from "components/buttons/UndoButton";

import { useAppDispatch } from "store/hooks";

import type { LocationState } from "types/location";
import type { ProductState } from "types/product";

interface Props {
    isLocationDialogOpen: boolean;
    scannedProducts: ProductState[];
    setIsLocationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setScannedProducts: React.Dispatch<React.SetStateAction<ProductState[]>>;
}

const LocationPickDialog = ({
    isLocationDialogOpen,
    scannedProducts,
    setIsLocationDialogOpen,
    setScannedProducts
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const LOCATION_BARCODE_SPLIT_LENGTH = 4;

    const [isRelocationToConfirm, setIsRelocationToConfirm] = useState<boolean>(false);
    const [locationInputValue, setLocationInputValue] = useState<string>("");
    const [newStoringRail, setNewStoringRail] = useState<LocationState>();
    const [relocationResult, setRelocationResult] = useState<any>();
    const [currentIndex, setCurrentIndex] = useState(0);

    const inventoryStates = [
        INVENTORY_STATE_TO_STORE_RETRIEVAL,
        INVENTORY_STATE_CARDBOARD_BERRY,
        INVENTORY_STATE_CARDBOARD_DON,
        INVENTORY_STATE_CARDBOARD_STANDBY
    ];

    useEffect(() => {
        const inventoryState = getFirstMatchingInventoryState(scannedProducts, inventoryOrder);
        const nextIndex = inventoryStates.indexOf(inventoryState);

        if (nextIndex !== -1 && nextIndex !== currentIndex) {
            setCurrentIndex(nextIndex);
        }
    }, [scannedProducts]);

    const { control } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange"
    });

    const handleClose = (): void => {
        setIsLocationDialogOpen(false);
    };

    const handleRelocationConfirmation = async (event: {
        preventDefault: () => void;
    }): Promise<void> => {
        event.preventDefault();

        const result = await handleCallErrorWithLoader(
            dispatch,
            fetchLocationFromBarcode,
            locationInputValue
        );
        if (result) {
            setNewStoringRail(result);
            setIsRelocationToConfirm(true);
        } else {
            setLocationInputValue("");
        }
    };

    const handleRelocation = async (): Promise<void> => {
        const data: any = { products: [] };
        for (const product of filteredProducts) {
            data.products.push(product.sku);
        }

        if (newStoringRail) {
            const result = await handleCallErrorWithLoader(
                dispatch,
                patchProductsLocation,
                newStoringRail.id,
                data
            );

            if (result) {
                setRelocationResult(result);
            }
        }
    };

    const handleUndo = (): void => {
        setIsRelocationToConfirm(false);
        setLocationInputValue("");
    };

    const inventoryOrder = [
        INVENTORY_STATE_TO_STORE_RETRIEVAL,
        INVENTORY_STATE_CARDBOARD_BERRY,
        INVENTORY_STATE_CARDBOARD_DON,
        INVENTORY_STATE_CARDBOARD_STANDBY
    ];

    function getFirstMatchingInventoryState(
        scannedProducts: ProductState[],
        inventoryOrder: string[]
    ): string {
        for (const inventoryState of inventoryOrder) {
            if (
                scannedProducts.some(
                    (product) => product.inventory_product_state === inventoryState
                )
            ) {
                return inventoryState;
            }
        }
        return "";
    }

    const filters = [
        (product: ProductState) =>
            product.inventory_product_state === INVENTORY_STATE_TO_STORE_RETRIEVAL,
        (product: ProductState) =>
            product.inventory_product_state === INVENTORY_STATE_CARDBOARD_BERRY,
        (product: ProductState) =>
            product.inventory_product_state === INVENTORY_STATE_CARDBOARD_DON,
        (product: ProductState) =>
            product.inventory_product_state === INVENTORY_STATE_CARDBOARD_STANDBY,
        (product: any) => true
    ];

    const STANDBY_CARDBOARD_BARDCORE = "STANDBY";
    const DON_CARDBOARD_BARDCORE = "CARTON-PAC";
    const CARDBOARD_BERRY_BARCODE = "CARTON-BERRY";

    async function handleDonationCardboardRelocation(barcode: string): Promise<void> {
        const result = await handleCallErrorWithLoader(dispatch, fetchLocationFromBarcode, barcode);

        if (result) {
            setNewStoringRail(result);
            setIsRelocationToConfirm(true);
        } else {
            setLocationInputValue("");
        }
    }

    const handlePACRelocation = (event: any): void => {
        setLocationInputValue(DON_CARDBOARD_BARDCORE);

        handleDonationCardboardRelocation(DON_CARDBOARD_BARDCORE);
    };

    const handleStandbyRelocation = async (event: any): Promise<void> => {
        setLocationInputValue(STANDBY_CARDBOARD_BARDCORE);

        handleDonationCardboardRelocation(STANDBY_CARDBOARD_BARDCORE);
    };

    const handleBerryRelocation = async (event: any): Promise<void> => {
        setLocationInputValue(CARDBOARD_BERRY_BARCODE);

        handleDonationCardboardRelocation(CARDBOARD_BERRY_BARCODE);
    };
    const filteredProducts = scannedProducts.filter(filters[currentIndex]);

    return (
        <Dialog
            aria-labelledby="location-pick-dialog"
            fullWidth
            onClose={handleClose}
            open={isLocationDialogOpen}
        >
            <DialogTitle
                bgcolor="secondary"
                id="location-pick-dialog"
                sx={{ textAlign: "center", fontWeight: "bold" }}
            >
                <Box>
                    Rangement de {filteredProducts.length} produits <br />{" "}
                    <p
                        style={{
                            color: getInventoryStateColor(inventoryStates[currentIndex]),
                            fontWeight: "bold"
                        }}
                    >
                        {inventoryStates[currentIndex]}
                    </p>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center" }}>
                {!relocationResult && (
                    <>
                        {/* {!isRelocationToConfirm && (
                            <SuggestedSubrack scannedProducts={filteredProducts} />
                            // TODO : A delete
                        )} */}

                        <form onSubmit={handleRelocationConfirmation}>
                            <ScanInputField
                                autoFocus
                                color="primary"
                                control={control}
                                inputValue={locationInputValue}
                                isSpecCharReplaceNeeded={true}
                                name="Location finale"
                                setInputValue={setLocationInputValue}
                                type="string"
                            />
                        </form>

                        {(inventoryStates[currentIndex] === INVENTORY_STATE_CARDBOARD_BERRY ||
                            inventoryStates[currentIndex] === INVENTORY_STATE_CARDBOARD_DON ||
                            inventoryStates[currentIndex] ===
                                INVENTORY_STATE_CARDBOARD_STANDBY) && (
                            <Button
                                color="secondary"
                                onClick={(event) => {
                                    if (
                                        inventoryStates[currentIndex] ===
                                        INVENTORY_STATE_CARDBOARD_BERRY
                                    ) {
                                        handleBerryRelocation(event);
                                    } else if (
                                        inventoryStates[currentIndex] ===
                                        INVENTORY_STATE_CARDBOARD_STANDBY
                                    ) {
                                        handleStandbyRelocation(event);
                                    } else if (
                                        inventoryStates[currentIndex] ===
                                        INVENTORY_STATE_CARDBOARD_DON
                                    ) {
                                        handlePACRelocation(event);
                                    }
                                }}
                                variant="contained"
                            >
                                {inventoryStates[currentIndex] === INVENTORY_STATE_CARDBOARD_BERRY
                                    ? "Berry"
                                    : inventoryStates[currentIndex] ===
                                        INVENTORY_STATE_CARDBOARD_DON
                                      ? "Don"
                                      : "Standby"}
                            </Button>
                        )}

                        {!isRelocationToConfirm && locationInputValue && (
                            <>
                                {locationInputValue.split("-").length <
                                    LOCATION_BARCODE_SPLIT_LENGTH && (
                                    <Typography color="error">
                                        Attention {locationInputValue} n&apos;est pas valide
                                    </Typography>
                                )}
                                <Button
                                    color="primary"
                                    onClick={handleRelocationConfirmation}
                                    variant="contained"
                                >
                                    Confirmer le rangement
                                </Button>
                            </>
                        )}
                    </>
                )}
                {isRelocationToConfirm && (
                    <>
                        <Typography>
                            {relocationResult
                                ? `✅ ${String(
                                      filteredProducts.length
                                  )} pièces déplacées en ${String(newStoringRail?.label)}`
                                : `Ranger ${String(filteredProducts.length)} pièces en ${String(
                                      newStoringRail?.label
                                  )}`}
                        </Typography>

                        {relocationResult?.errors?.length ? (
                            <>
                                <Typography color="error" sx={{ textAlign: "center" }}>
                                    Erreurs de relocation :
                                </Typography>

                                {relocationResult.errors.map((itemQr: any, i: number) => {
                                    return (
                                        <Typography
                                            key={i}
                                            color="error"
                                            sx={{ textAlign: "center" }}
                                        >
                                            {`❌ ${String(itemQr)}`}
                                        </Typography>
                                    );
                                })}
                            </>
                        ) : (
                            <></>
                        )}

                        <Box mt={3} sx={{ display: "flex", justifyContent: "center" }}>
                            {!relocationResult && (
                                <UndoButton handleUndo={handleUndo} size="large" />
                            )}
                            <ChoiceButton
                                color="primary"
                                onClick={() => {
                                    relocationResult
                                        ? (() => {
                                              setIsLocationDialogOpen(false);
                                              setIsRelocationToConfirm(false);
                                              setRelocationResult(null);
                                              setLocationInputValue("");

                                              // When relocationResult is successful,
                                              // we remove filteredProducts from scannedProducts
                                              const newScannedProducts = scannedProducts.filter(
                                                  (scannedProduct) =>
                                                      !filteredProducts.some(
                                                          (filteredProduct) =>
                                                              filteredProduct.sku ===
                                                              scannedProduct.sku
                                                      )
                                              );
                                              setScannedProducts(newScannedProducts);
                                              localStorage.setItem(
                                                  "scannedProducts",
                                                  JSON.stringify(newScannedProducts)
                                              );
                                          })()
                                        : handleRelocation();
                                }}
                                sx={{ fontSize: "12px" }}
                                text={relocationResult ? "Retour" : "✅ Valider le rangement"}
                                variant="contained"
                            />
                        </Box>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default LocationPickDialog;
